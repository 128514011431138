<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form">
                    <v-card :elevation="1" color="indigo lighten-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Present Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="3">Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox 
                                                        v-model="searchType"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['New', 'Appointment']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" v-if="searchType == 'Appointment'">
                                            <v-row no-gutters>
                                                <v-col cols="3">Patient</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="patients"
                                                        item-value="id"
                                                        item-text="name"
                                                        v-model="selectedPatient"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" v-if="searchType == 'New'">
                                            <v-row no-gutters>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        label="Name"
                                                        placeholder="text here..."
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        class="ml-1"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        label="Mobile"
                                                        placeholder="01xxxxxxxx"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="3">Doctor</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['D0001 - Jakirul Islam', 'D0002 - Shadhin']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters>
                                                <v-col cols="3">Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="date"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn height="26px" dark block color="light-blue darken-2">Save</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="presentHeaders"
                    :items="presents"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="caption">Present Entry</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Present"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editDoctor(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="deleteDoctor()" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'PresentEntry',
    data: ()=> ({
        date: new Date().toISOString().substr(0, 10),
        searchType: null,

        presentHeaders: [
            { text: 'Sl', value: 'sl' },
            { text: 'Patient Name', value: 'name' },
            { text: 'Mobile', value: 'mobile' },
            { text: 'Doctor Name', value: 'docName' },
            { text: 'Date', value: 'date' },
            { text: 'Action', value: 'action' },
        ],

        presents: [
            {   
                sl: 1,
                name: 'Jakirul Islam',
                mobile: '01796953487',
                docName: 'Mr X',
                date: '08-11-2020'
            },
            {   
                sl: 2,
                name: 'Jakirul Islam',
                mobile: '01796953487',
                docName: 'Mr X',
                date: '08-11-2020'
            },
            {   
                sl: 3,
                name: 'Jakirul Islam',
                mobile: '01796953487',
                docName: 'Mr X',
                date: '08-11-2020'
            },
            {   
                sl: 4,
                name: 'Jakirul Islam',
                mobile: '01796953487',
                docName: 'Mr X',
                date: '08-11-2020'
            },
            {   
                sl: 5,
                name: 'Jakirul Islam',
                mobile: '01796953487',
                docName: 'Mr X',
                date: '08-11-2020'
            },
        ],

        patients: [
            {
                id: 1,
                name: 'Jhon Doe',
                phone: '01234567895',
                address: 'Mirpur-10',
            },
            {
                id: 2,
                name: 'Mustafij Vai',
                phone: '01234567895',
                address: 'Mirpur-10',
            },
            {
                id: 3,
                name: 'Mondol Vai',
                phone: '01234567895',
                address: 'Mirpur-10',
            }
        ],

        selectedPatient: {
            id: null,
            name: null,
            phone: null,
        },

    }),
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .subtitle-2 {
        color: #fff !important;
    }
    .caption {
        font-size: 15px !important;
    }
</style>